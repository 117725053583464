
import {
  defineComponent,
  ref,
  onMounted,
  computed,
  watch,
  onBeforeMount,
  ComputedRef,
} from "vue";

import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { FolderRequest } from "../../core/types/FolderTypes";
import { FolderSurveyLineItemDto } from "../../core/types/SurveyTypes";
import {
  ExportBehavioralSurveyLocationRequestDto,
  ExportToExcelResponseDto,
} from "../../core/types/ExcelTypes";

export default defineComponent({
  name: "all-client-behavioral-surveys-folder",
  components: {
    TableSkeleton,
  },
  setup() {
    const store = useStore();
    const exportToExcelButton = ref<HTMLButtonElement | null>(null);
    const isDataReady = ref(false);
    const isExcelFileReady = ref(false);
    const currentUser = store.getters.currentUser;
    let displayModal = ref<boolean>(false);
    const { d } = useI18n();
    const folderRequest = ref<FolderRequest>({
      currentUserRole: currentUser.role,
      currentUserId: currentUser.id,
      folderId: 0,
      folderName: "",
      getAllSurveys: true,
      clientId: 0,
      clientLocationId: 0,
    });
    const exportBehavioralSurveyLocationRequestDto =
      ref<ExportBehavioralSurveyLocationRequestDto>({
        lineItems: [],
        allBehavioralSurveys: true,
      });
    const excelFileResponse: ComputedRef<ExportToExcelResponseDto> = computed(
      () => {
        return store.getters.getExcelFile;
      }
    );
    const folderLineItems = computed(() => {
      return store.getters.getFolderContents;
    });
    const searchSurveys = ref("");

    const currentClient = computed(() => {
      return store.getters.getCurrentClientInfo;
    });
    const searchByPlacholder = ref("");

    folderRequest.value.folderName = "Behavioral-Surveys";
    searchByPlacholder.value = "Search by name, profilename or status...";

    if (currentUser.role === "PowerUser" || currentUser.role === "ClientUser") {
      folderRequest.value.clientId = currentClient.value.client.clientId;
    }

    store
      .dispatch(Actions.GET_CLIENT_FOLDER_CONTENTS, folderRequest.value)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving folder contents.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });
    onBeforeMount(() => {
      store.commit(Mutations.PURGE_FOLDER_CONTENTS);
    });

    onMounted(() => {
      setCurrentPageTitle("Client Folder");
      if (
        currentUser.role === "PowerUser" ||
        currentUser.role === "ClientUser"
      ) {
        setCurrentPageBreadcrumbs(currentClient.value.name, ["Client"]);
      }
    });

    const filterSurveys: ComputedRef<Array<FolderSurveyLineItemDto>> = computed(
      () => {
        return folderLineItems.value.filter((survey) => {
          return (
            survey.name
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.profileName
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.surveyStatus
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1 ||
            survey.jobTitle
              .toLowerCase()
              .indexOf(searchSurveys.value.toLowerCase()) != -1
          );
        });
      }
    );

    watch(folderLineItems, () => {
      isDataReady.value = true;
    });

    watch(excelFileResponse, () => {
      if (excelFileResponse.value.excelFilename !== "na") {
        isExcelFileReady.value = true;

        //Deactivate indicator
        exportToExcelButton.value?.removeAttribute("data-kt-indicator");
        // eslint-disable-next-line
          exportToExcelButton.value!.disabled = false;
      } else if (excelFileResponse.value.excelFilename === "na") {
        isExcelFileReady.value = false;
      }
    });

    function exportToExcel() {
      exportBehavioralSurveyLocationRequestDto.value.lineItems =
        folderLineItems.value;
      store.commit(Mutations.PURGE_EXCEL_FILE);

      store
        .dispatch(
          Actions.EXPORT_BEHAVIORAL_SURVEYS_TO_EXCEL,
          exportBehavioralSurveyLocationRequestDto.value
        )
        .then(() => {
          //
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors[0],
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "There was a problem with sync",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      if (exportToExcelButton.value) {
        // eslint-disable-next-line
        exportToExcelButton.value!.disabled = true;
        // Activate indicator
        exportToExcelButton.value.setAttribute("data-kt-indicator", "on");
      }
    }

    return {
      currentUser,
      currentClient,
      isDataReady,
      isExcelFileReady,
      d,
      searchByPlacholder,
      folderLineItems,
      searchSurveys,
      displayModal,
      filterSurveys,
      folderRequest,
      exportBehavioralSurveyLocationRequestDto,
      exportToExcel,
      excelFileResponse,
      exportToExcelButton,
    };
  },
});
